import { format } from 'date-fns/format';
import { formatDistanceToNow } from 'date-fns/formatDistanceToNow';
import type { FormatDistanceOptions } from 'date-fns/formatDistance';
import { formatInTimeZone, fromZonedTime } from 'date-fns-tz';
import type { DateRange, TimeRange } from '../components/DatePicker/types';

type DateType = Date | string | number;

export const DateFormats = {
  dmyhms: 'dd MMM yyyy, H:mm:ss',
  dmyhma: 'dd MMM yyyy, H:mm a',
  dmyhm: 'dd MMM yyyy, H:mm',
  mdyhma: 'MMM dd, yyyy h:mma',
  mdyhm: 'MM/dd/yyyy H:mm',
  Mdy: 'MMMM dd, yyyy',
  dMy: 'd MMM yyyy',
  yyyyMMdd: 'yyyy-MM-dd',
  ddMMM: 'dd MMM',
  'relative-time-to-now': formatDistanceToNow,
} as const;

export function formatDate(date: DateType, formatStr: string): string {
  if (!date) {
    return '';
  }
  try {
    const numericDate = Number(date);
    const isStringifiedEpoch = !Number.isNaN(numericDate);
    return isStringifiedEpoch ? format(numericDate, formatStr) : format(date, formatStr);
  } catch (e) {
    console.debug(e);
    return date as string; // return as is
  }
}

export function formatDateByFormatType(
  date: DateType,
  formatType: string | undefined,
  options?: FormatDistanceOptions,
) {
  // default case
  if (!formatType) return formatDate(date, DateFormats.mdyhm);

  // if we have defined mappings
  if (formatType in DateFormats) {
    const formatter = DateFormats[formatType as keyof typeof DateFormats];

    if (typeof formatter === 'function') {
      return formatter(date, options);
    }
    return formatDate(date, formatter);
  }

  // send to date-fns if it can handle it
  return formatDate(date, formatType);
}

export function getDateByFormatType(date: Date | undefined, formatType: string | undefined) {
  switch (formatType) {
    case 'iso8601':
      return date?.toISOString();
    case 'unix':
      return date?.getTime();
    default:
      return date?.getTime();
  }
}

export function convertISOStringToDateRange(timeRange?: TimeRange | null): DateRange | undefined {
  if (!timeRange?.since && !timeRange?.until) return;

  return {
    since: timeRange.since ? new Date(timeRange.since) : undefined,
    until: timeRange.until ? new Date(timeRange.until) : undefined,
  };
}

export function convertDateRangeToISOString(dateRange?: DateRange | null): TimeRange | undefined {
  if (!dateRange?.since && !dateRange?.until) return;

  return {
    since: dateRange.since ? dateRange.since.toISOString() : undefined,
    until: dateRange.until ? dateRange.until.toISOString() : undefined,
  };
}

const getTimezone = () => {
  if (typeof Intl === 'undefined') {
    return 'UTC';
  }
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return tz;
};

/**
 * Converts a UTC date string to a formatted date string in the specified time zone.
 * @param utcDateString - The UTC date string (e.g., "2024-08-24T18:00:00Z")
 * @param timeZone - The IANA time zone identifier
 * @returns A formatted date string in the local time zone.
 */
export function utcDateStringToLocal(utcDateString: string): string {
  const utcDate = new Date(utcDateString);
  const timeZone = getTimezone();
  return formatInTimeZone(utcDate, timeZone, "yyyy-MM-dd'T'HH:mm:ssXXX", { timeZone });
}

/**
 * Converts a local date string to a formatted date string in UTC.
 * @param localDate - The local date string (e.g., "2024-08-24T18:00:00+05:30")
 * @returns A formatted date string in UTC.
 */
export function localDateToUTCString(localDate: string): string {
  const timeZone = getTimezone();
  return fromZonedTime(localDate, timeZone).toISOString();
}

export function convertUTCTimeRangeToTimezoneRange(timeRange: TimeRange): TimeRange {
  return {
    since: timeRange.since ? utcDateStringToLocal(timeRange.since) : undefined,
    until: timeRange.until ? utcDateStringToLocal(timeRange.until) : undefined,
  };
}

export function convertTimezoneRangeToUTCTimeRange(timeRange: TimeRange): TimeRange {
  return {
    since: timeRange.since ? localDateToUTCString(timeRange.since) : undefined,
    until: timeRange.until ? localDateToUTCString(timeRange.until) : undefined,
  };
}
